// Header script content for analytics and beamer config
export const headerScriptContent = `
  if (window?.env?.GOOGLE_ANALYTICS_CLIENT_KEY) {
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src =
      'https://www.googletagmanager.com/gtag.js?id=' +
      window.env.GOOGLE_ANALYTICS_CLIENT_KEY;
    const elementScript = document.getElementsByTagName('script')[0];
    elementScript.parentNode.insertBefore(gtagScript, elementScript);
  }
  
  window.dataLayer = window.dataLayer || [];
  
  function gtag() {
    dataLayer.push(arguments);
  }
  
  gtag('js', new Date());
  gtag('config', window.env.GOOGLE_ANALYTICS_CLIENT_KEY);
  
  var beamer_config = {
    product_id: window.env.BEAMER_KEY, // DO NOT CHANGE: This is your product code on Beamer
    selector: ".beamerButton",
    // The button config disables the default button, which appears when 
    // the '.beamerButton' is not in the DOM
    button: false,
    counter: false,
    lazy: true,
  };
`;

// Body script content for Inspectlet setup
export const bodyScriptContent = `
  (function () {
    if (window?.env?.INSPECTLET_KEY) {
      window.__insp = window.__insp || [];
      __insp.push(['wid', window.env.INSPECTLET_KEY]);
      
      var ldinsp = function () {
        if (typeof window.__inspld !== 'undefined') return;
        window.__inspld = 1;
        var insp = document.createElement('script');
        insp.type = 'text/javascript';
        insp.async = true;
        insp.id = 'inspsync';
        insp.src =
          (document.location.protocol === 'https:' ? 'https' : 'http') +
          '://cdn.inspectlet.com/inspectlet.js?wid=' +
          window.env.INSPECTLET_KEY +
          '&r=' +
          Math.floor(new Date().getTime() / 3600000);
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(insp, x);
      };
      
      setTimeout(ldinsp, 0);
    }
  })();
`;
