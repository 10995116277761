import React from 'react';
import { Theme } from '@airtame/ui';
import { Links, LinksFunction, Meta, Outlet, Scripts, ScrollRestoration } from 'react-router';
import globalStyles from 'src/assets/scss/index.scss?url';
import { Loader } from 'src/components/ui/Loader';
import { ViteMode } from 'src/typings/global';
import { RootError } from './RootError';
import { InjectScript } from './scripts/InjectScript';
import { bodyScriptContent, headerScriptContent } from './scripts/scriptContent';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: globalStyles }];

export function Layout({ children }: { children: React.ReactNode }): React.ReactElement {
  return (
    <html
      lang="en"
      // @ts-expect-error gitrevisionversion is not standard
      // eslint-disable-next-line react/no-unknown-property
      gitrevisionversion={__RELEASE_VERSION__}
      data-theme="dark"
      suppressHydrationWarning
    >
      <head>
        <Meta />
        <Links />
        <title>Airtame Cloud</title>
        <meta charSet="UTF-8" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Airtame" />
        <meta
          name="description"
          content="Airtame Cloud - Manage all your Airtames from the Cloud"
        />
        <meta
          property="og:description"
          content="Airtame Cloud - Manage all your Airtames from the Cloud"
        />
        <meta property="og:title" content="Airtame Cloud" />
        <meta property="og:url" content="https://airtame.cloud/" />
        <meta property="og:image" content="/images/favicons/favicon-32x32.png" />
        <script src={`/env-config.js?_=${__RELEASE_VERSION__}`} suppressHydrationWarning />
        {__MODE__ === ViteMode.production && (
          <script
            suppressHydrationWarning
            src="https://app.getbeamer.com/js/beamer-embed.js"
            defer
            type="text/javascript"
          />
        )}
        <InjectScript scriptContent={headerScriptContent} />
      </head>
      <body suppressHydrationWarning>
        {children}

        <div className="airtame-modal-root" id="airtame-modal-root"></div>
        <div className="airtame-dialog-root" id="airtame-dialog-root"></div>
        <div className="airtame-sidedrawer-root" id="airtame-sidedrawer-root"></div>
        <ScrollRestoration />
        <Scripts />
        <InjectScript scriptContent={bodyScriptContent} />
      </body>
    </html>
  );
}

export default function Root(): React.ReactElement {
  return <Outlet />;
}

export const HydrateFallback = (): React.ReactElement => {
  return <Loader />;
};

export const ErrorBoundary = (): React.ReactElement => {
  return (
    <Theme theme="dark">
      <RootError />
    </Theme>
  );
};
